<nav class="menu navbar navbar-expand-md navbar-light bg-faded">
  <ul class="navbar-nav mx-auto">
    <li class="nav-item active text-center">
      <a class="nav-link" href="/main">Main</a>
    </li>
    <li class="nav-item active text-center">
      <a class="nav-link" href="/work">Work</a>
    </li>
    <li class="nav-item active text-center">
      <a class="nav-link" href="/travel">Travel</a>
    </li>
    <li class="nav-item text-center">
      <a class="nav-link" href="/contact">Contact</a>
    </li>
  </ul>
</nav>
