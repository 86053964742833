import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WorksService {

  works = [
    {
      title: 'Quad',
      url: 'Quad',
      type: 'work',
      thumbImg: 'assets/img/works/quad/thumbnail.jpg',
      videos: [
      ],
      frames: [
        'assets/img/works/quad/frame1.jpg',
        'assets/img/works/quad/frame2.jpg',
        'assets/img/works/quad/frame3.jpg',
        'assets/img/works/quad/frame4.jpg',
        'assets/img/works/quad/frame5.jpg',
        'assets/img/works/quad/frame6.jpg',
        'assets/img/works/quad/frame7.jpg',
        'assets/img/works/quad/frame8.jpg',
      ]
    },
    {
      title: 'Lofoten',
      url: 'Lofoten',
      type: 'travel',
      thumbImg: 'assets/img/works/lofoten/thumb.jpg',
      videos: [
        {
          id: "TZrz0mIHohE",
          platform: "",
          url: "https://www.youtube.com/embed/TZrz0mIHohE"
        }
      ],
      frames: [
        'assets/img/works/lofoten/frame1.jpg',
        'assets/img/works/lofoten/frame2.jpg',
        'assets/img/works/lofoten/frame3.jpg',
        'assets/img/works/lofoten/frame4.jpg',
        'assets/img/works/lofoten/frame5.jpg',
        'assets/img/works/lofoten/frame6.jpg',
        'assets/img/works/lofoten/frame7.jpg',
        'assets/img/works/lofoten/frame8.jpg',
        'assets/img/works/lofoten/frame9.jpg',
        'assets/img/works/lofoten/frame10.jpg',
        'assets/img/works/lofoten/frame11.jpg',
        'assets/img/works/lofoten/frame12.jpg',
        'assets/img/works/lofoten/frame13.jpg',
      ]
    },
    {
      title: 'Picos de Europa',
      url: 'picos-de-europa',
      type: 'travel',
      thumbImg: 'assets/img/works/picos/thumb.jpg',
      videos: [
        {
          id: "xgWyNu5fjqA",
          platform: "",
          url: "https://www.youtube.com/embed/xgWyNu5fjqA"
        }
      ],
      frames: [
      ]
    },
    {
      title: 'The Airback',
      url: 'airback',
      type: 'work',
      thumbImg: 'assets/img/works/airback/thumb.jpg',
      videos: [
        {
          id: "Bv2SI3mTclY",
          platform: "",
          url: "https://www.youtube.com/embed/Bv2SI3mTclY"
        }
      ],
      frames: [
        'assets/img/works/airback/frame1.jpg',
        'assets/img/works/airback/frame2.jpg',
        'assets/img/works/airback/frame3.jpg',
        'assets/img/works/airback/frame4.jpg',
        'assets/img/works/airback/frame5.jpg',
        'assets/img/works/airback/frame6.jpg',
      ]
    },
    {
      title: 'Serra da Estrela',
      url: 'serra-da-estrela-2023',
      type: 'travel',
      thumbImg: 'assets/img/works/serra-2/SERRA2.jpg',
      videos: [
        {
          id: "pthyeLksbJQ",
          platform: "",
          url: "https://www.youtube.com/embed/pthyeLksbJQ"
        }
      ],
      frames: [
        'assets/img/works/serra-2/serra da estrela 22 frame 1.jpg',
        'assets/img/works/serra-2/serra da estrela 22 frame 2.jpg',
        'assets/img/works/serra-2/serra da estrela 22 frame 3.jpg',
        'assets/img/works/serra-2/serra da estrela 22 frame 4.jpg',
        'assets/img/works/serra-2/serra da estrela 22 frame 5.jpg',
        'assets/img/works/serra-2/serra da estrela 22 frame 6.jpg',
      ]
    },
    {
      title: 'Music video',
      url: 'music-video',
      type: 'work',
      thumbImg: 'assets/img/works/musicvideo/MUSIC-VIDEO-2.jpg',
      videos: [
        {
          id: "qaLwf_vsECs",
          platform: "",
          url: "https://www.youtube.com/embed/qaLwf_vsECs"
        }
      ],
      frames: [
        'assets/img/works/musicvideo/ill never know him frame 1.jpg',
        'assets/img/works/musicvideo/ill never know him frame 2.jpg',
        'assets/img/works/musicvideo/ill never know him frame 3.jpg',
        'assets/img/works/musicvideo/ill never know him frame 5.jpg',
        'assets/img/works/musicvideo/ill never know him frame 6.jpg',
        'assets/img/works/musicvideo/ill never know him frame 7.jpg',
        'assets/img/works/musicvideo/ill never know him frame 11.jpg',
        'assets/img/works/musicvideo/ill never know him frame 12.jpg',
        'assets/img/works/musicvideo/ill never know him frame 13.jpg',
        'assets/img/works/musicvideo/ill never know him frame 17.jpg',
      ]
    },
    {
      title: 'A Chuva - NGA & Van Sophie',
      url: 'a-chuva',
      type: 'work',
      thumbImg: 'assets/img/works/achuva/MUSIC-VIDEO-1.jpg',
      videos: [
        {
          id: "c2JPIc383wY",
          platform: "",
          url: "https://www.youtube.com/embed/c2JPIc383wY"
        }
      ],
      frames: [
        'assets/img/works/achuva/a chuva 1.jpg',
        'assets/img/works/achuva/a chuva 2.jpg',
        'assets/img/works/achuva/a chuva 3.jpg',
        'assets/img/works/achuva/a chuva 4.jpg',
        'assets/img/works/achuva/a chuva 5.jpg',
        'assets/img/works/achuva/a chuva 6.jpg',
        'assets/img/works/achuva/a chuva 7.jpg',
        'assets/img/works/achuva/a chuva 8.jpg',
        'assets/img/works/achuva/a chuva 9.jpg',
        'assets/img/works/achuva/a chuva 10.jpg',
        'assets/img/works/achuva/a chuva 11.jpg',
        'assets/img/works/achuva/a chuva 12.jpg',
        'assets/img/works/achuva/a chuva 13.jpg',
        'assets/img/works/achuva/a chuva 14.jpg',
        'assets/img/works/achuva/a chuva 16.jpg',
        'assets/img/works/achuva/a chuva 17.jpg',
      ]
    },
    {
      title: 'Not Here By Luck',
      url: 'not-here-by-luck',
      type: 'work',
      thumbImg: 'assets/img/works/notherebyluck/thumb.jpg',
      videos: [
        {
          id: 'T9ApjevZUQk',
          platform: 'youtube',
          url: "https://www.youtube.com/embed/T9ApjevZUQk",
        },
      ],
      frames: [
        'assets/img/works/notherebyluck/nhbl frame 1.jpg',
        'assets/img/works/notherebyluck/nhbl frame 2.jpg',
        'assets/img/works/notherebyluck/nhbl frame 3.jpg',
        'assets/img/works/notherebyluck/nhbl frame 5.jpg',
        'assets/img/works/notherebyluck/nhbl frame 7.jpg',
        'assets/img/works/notherebyluck/nhbl frame 9.jpg',
        'assets/img/works/notherebyluck/nhbl frame 10.jpg',
        'assets/img/works/notherebyluck/nhbl frame 11.jpg',
      ]
    },
    {
      title: 'The Unexpected Journey',
      url: 'the-unexpected-journey',
      type: 'travel',
      thumbImg: 'assets/img/works/jordan2023/JORDAN-2.jpg',
      videos: [
        {
          id: "ComQjeCUx90",
          platform: "",
          url: "https://www.youtube.com/embed/ComQjeCUx90"
        }
      ],
      frames: [
        'assets/img/works/jordan2023/jordan 1.jpg',
        'assets/img/works/jordan2023/jordan 2.jpg',
        'assets/img/works/jordan2023/jordan 3.jpg',
        'assets/img/works/jordan2023/jordan 4.jpg',
        'assets/img/works/jordan2023/jordan 5.jpg',
        'assets/img/works/jordan2023/jordan 6.jpg',
        'assets/img/works/jordan2023/jordan 7.jpg',
        'assets/img/works/jordan2023/jordan 8.jpg',
        'assets/img/works/jordan2023/jordan 9.jpg',
        'assets/img/works/jordan2023/jordan 10.jpg',
        'assets/img/works/jordan2023/jordan 11.jpg',
        'assets/img/works/jordan2023/jordan 12.jpg',
        'assets/img/works/jordan2023/jordan 13.jpg',
        'assets/img/works/jordan2023/jordan 14.jpg',
        'assets/img/works/jordan2023/jordan 15.jpg',
        'assets/img/works/jordan2023/jordan 16.jpg',
        'assets/img/works/jordan2023/jordan 17.jpg',
        'assets/img/works/jordan2023/jordan 18.jpg',
        'assets/img/works/jordan2023/jordan 19.jpg',
        'assets/img/works/jordan2023/jordan 20.jpg',
        'assets/img/works/jordan2023/jordan 21.jpg',
      ]
    },
    {
      title: 'Madeira 2022',
      url: 'follow-your-passion-madeira-2022',
      type: 'travel',
      thumbImg: 'assets/img/works/madeira2022/thumb.jpg',
      videos: [
        {
          id: '515p0sIqyO8',
          platform: 'Youtube',
          url: "https://www.youtube.com/embed/515p0sIqyO8",
        }
      ],
      frames: [
        'assets/img/works/madeira2022/fyp-madeira-2.jpg',
        'assets/img/works/madeira2022/fyp-madeira-3.jpg',
        'assets/img/works/madeira2022/fyp-madeira-4.jpg',
        'assets/img/works/madeira2022/fyp-madeira-5.jpg',
        'assets/img/works/madeira2022/fyp-madeira-6.jpg',
        'assets/img/works/madeira2022/fyp-madeira-7.jpg',
        'assets/img/works/madeira2022/fyp-madeira-8.jpg',
        'assets/img/works/madeira2022/fyp-madeira-9.jpg',
        'assets/img/works/madeira2022/fyp-madeira-10.jpg',
        'assets/img/works/madeira2022/fyp-madeira-11.jpg',
        'assets/img/works/madeira2022/fyp-madeira-12.jpg',
        'assets/img/works/madeira2022/fyp-madeira-14.jpg',
      ]
    },
    {
      title: 'Serra da Estrela',
      url: 'serra-da-estrela',
      type: 'travel',
      thumbImg: 'assets/img/works/serradaestrela/thumb.jpg',
      videos: [
        {
          id: 'ZkMVC4I5_m4',
          platform: 'Youtube',
          url: "https://www.youtube.com/embed/ZkMVC4I5_m4",

        }
      ],
      frames: [
        'assets/img/works/serradaestrela/sec3.jpg',
        'assets/img/works/serradaestrela/sec5.jpg',
        'assets/img/works/serradaestrela/sec7.jpg',
        'assets/img/works/serradaestrela/sec14.jpg',
        'assets/img/works/serradaestrela/sec15.jpg',
        'assets/img/works/serradaestrela/sec17.jpg',
        'assets/img/works/serradaestrela/sec21.jpg',
        'assets/img/works/serradaestrela/sec24.jpg',
      ]
    },
    {
      title: 'RFM Somni 2022',
      url: 'rfm-somni-2022',
      type: 'work',
      thumbImg: 'assets/img/works/rfmsomni2022/thumb.jpg',
      videos: [
        {
          id: 'AuSdjLEb_Js',
          platform: 'youtube',
          url: "https://www.youtube.com/embed/AuSdjLEb_Js",
        },
        {
          id: '732944619',
          platform: 'vimeo',
          url: 'https://player.vimeo.com/video/732944619'
        },
        {
          id: 'XbyLjTo16r0',
          platform: 'Youtube',
          url: "https://www.youtube.com/embed/XbyLjTo16r0",
        }
      ],
      frames: [
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f4.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f5.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f6.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f7.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f8.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f10.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f12.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f13.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f14.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f15.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f20.png',
        'assets/img/works/rfmsomni2022/rfm somnii 2022 f25.png',
      ]
    },
    {
      title: 'Nineteen Degrees',
      url: 'nineteen-degrees',
      type: 'main',
      thumbImg: 'assets/img/works/nineteendegrees/thumb.jpg',
      videos: [
        {
          id: 'PjPmOo_m0Rg',
          platform: 'youtube',
          url: "https://www.youtube.com/embed/PjPmOo_m0Rg",
        },
      ],
      frames: [
        'assets/img/works/nineteendegrees/ND-1.jpg',
        'assets/img/works/nineteendegrees/ND-2.jpg',
        'assets/img/works/nineteendegrees/ND-3.jpg',
        'assets/img/works/nineteendegrees/ND-4.jpg',
        'assets/img/works/nineteendegrees/ND-5.jpg',

      ]
    },
    {
      title: 'Showreel 2021',
      url: 'showreel-2021',
      type: 'work',
      thumbImg: 'assets/img/works/showreel2021/thumb.jpg',
      videos: [
        {
          id: '8hm-UYKTPlo',
          platform: 'Youtube',
          url: "https://www.youtube.com/embed/8hm-UYKTPlo",
        }
      ],
      frames: []
    },
    {
      title: 'Algarve',
      url: 'algarve',
      type: 'travel',
      thumbImg: 'assets/img/works/algarve/thumb.jpg',
      videos: [
        {
          id: '8c4f3A_3Ebk',
          platform: 'Youtube',
          url: "https://www.youtube.com/embed/8c4f3A_3Ebk",
        }
      ],
      frames: []
    },
  ]


  constructor() { }

  getAll(type) {
    return this.works.filter(work => work.type == type);
  }

  getWorkByTitle(title) {
    return this.works.find(work => work.url === title);
  }
}
